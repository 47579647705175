*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Gotham, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 600px) {
  .container {
    max-width: 600px;
  }
}

@media (min-width: 728px) {
  .container {
    max-width: 728px;
  }
}

@media (min-width: 984px) {
  .container {
    max-width: 984px;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-left-\[26px\] {
  left: -26px;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-\[4\%\] {
  left: 4%;
}

.left-\[5px\] {
  left: 5px;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-\[0px\] {
  top: 0;
}

.top-\[20\%\] {
  top: 20%;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.z-\[9999\] {
  z-index: 9999;
}

.m-3 {
  margin: .75rem;
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.-mx-1\.5 {
  margin-left: -.375rem;
  margin-right: -.375rem;
}

.-my-1 {
  margin-top: -.25rem;
  margin-bottom: -.25rem;
}

.-my-1\.5 {
  margin-top: -.375rem;
  margin-bottom: -.375rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-\[2px\] {
  margin-top: 2px;
  margin-bottom: 2px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-\[2px\] {
  margin-right: 2px;
}

.mt-2 {
  margin-top: .5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-\[2\.8rem\] {
  height: 2.8rem;
}

.h-\[40px\] {
  height: 40px;
}

.h-\[4rem\] {
  height: 4rem;
}

.h-\[70px\] {
  height: 70px;
}

.h-\[90px\] {
  height: 90px;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-\[70px\] {
  max-height: 70px;
}

.max-h-\[7rem\] {
  max-height: 7rem;
}

.max-h-\[90px\] {
  max-height: 90px;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-\[2\.8rem\] {
  width: 2.8rem;
}

.w-\[40px\] {
  width: 40px;
}

.w-\[calc\(100\%-30px\)\] {
  width: calc(100% - 30px);
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-\[100px\] {
  max-width: 100px;
}

.max-w-\[500px\] {
  max-width: 500px;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1 {
  --tw-translate-y: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-90 {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[\.8\] {
  --tw-scale-x: .8;
  --tw-scale-y: .8;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-\[1\.2rem\] {
  gap: 1.2rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-\[\#333\] {
  --tw-border-opacity: 1;
  border-color: rgb(51 51 51 / var(--tw-border-opacity));
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 165 165 / var(--tw-border-opacity));
}

.bg-\[\#eefd00\] {
  --tw-bg-opacity: 1;
  background-color: rgb(238 253 0 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-hero-text {
  background-image: url("jbjz-header-text.6e1fb7f7.png");
}

.bg-contain {
  background-size: contain;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.object-contain {
  object-fit: contain;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-top {
  vertical-align: top;
}

.font-futura {
  font-family: Futura PT Cond Extra Bold, sans-serif;
}

.font-zing-rust {
  font-family: Zing Rust D2 Demo Base, sans-serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[2\.2rem\] {
  font-size: 2.2rem;
}

.text-\[5rem\] {
  font-size: 5rem;
}

.text-\[70px\] {
  font-size: 70px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-\[\.75\] {
  line-height: .75;
}

.leading-none {
  line-height: 1;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.no-underline {
  text-decoration-line: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-80 {
  opacity: .8;
}

.opacity-95 {
  opacity: .95;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-\[\#eefd00\] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(238 253 0 / var(--tw-ring-opacity));
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

@font-face {
  font-family: Futura PT Cond Extra Bold;
  src: url("futura_pt_cond_extra_bold.93906238.eot");
  src: url("futura_pt_cond_extra_bold.93906238.eot#iefix") format("embedded-opentype"), url("futura_pt_cond_extra_bold.5ae91f1e.woff2") format("woff2"), url("futura_pt_cond_extra_bold.67d2f73a.woff") format("woff"), url("futura_pt_cond_extra_bold.1afdc86b.ttf") format("truetype"), url("futura_pt_cond_extra_bold.276088a9.svg#futura_pt_cond_extra_bold") format("svg");
}

@font-face {
  font-family: Gotham;
  src: url("Gotham-LightItalic.8e84865d.woff2") format("woff2"), url("Gotham-LightItalic.4e8e19f5.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Gotham;
  src: url("Gotham-Book.15df0a99.woff2") format("woff2"), url("Gotham-Book.d16a6bc4.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gotham;
  src: url("Gotham-BookItalic.62b10a04.woff2") format("woff2"), url("Gotham-BookItalic.f75c3222.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Gotham;
  src: url("Gotham-MediumItalic.221d7d24.woff2") format("woff2"), url("Gotham-MediumItalic.38973c45.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Gotham;
  src: url("Gotham-Medium.de50fc37.woff2") format("woff2"), url("Gotham-Medium.3ca6dcc8.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gotham;
  src: url("Gotham-BoldItalic.9b493574.woff2") format("woff2"), url("Gotham-BoldItalic.3eeb71d6.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Gotham;
  src: url("Gotham-Light.32ee0e73.woff2") format("woff2"), url("Gotham-Light.b840c96e.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.50655a60.woff2") format("woff2"), url("Gotham-Bold.9c0eb552.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Zing Rust D2 Demo Base;
  src: url("ZingRustD2Demo-Base.6ae954ab.woff2") format("woff2"), url("ZingRustD2Demo-Base.c9462485.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

[x-cloak] {
  display: none !important;
}

.loader {
  border-top-color: #3498db;
  animation: 1.5s linear infinite spinner;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.font-reverse-slope {
  transform: scale(1)rotate(0)translate(0)skew(15deg);
}

.font-more-slope {
  transform: scale(1)rotate(0)translate(0)skew(-14deg);
}

html {
  scroll-behavior: smooth;
}

h2, h3 {
  font-family: Futura PT Cond Extra Bold;
  font-size: 3.5rem;
  line-height: .95;
  transform: scale(1)rotate(0)translate(0)skew(15deg);
}

h2 {
  margin-bottom: 30px;
  margin-left: -10px;
  font-size: 3.5rem;
}

h3 {
  margin-bottom: 20px;
  font-size: 2.2rem;
}

p {
  margin: 10px 0;
}

.curve {
  height: 250px;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
}

.curve:before {
  content: "";
  width: 55%;
  height: 100%;
  background-color: #202731;
  border-radius: 100% 50%;
  display: block;
  position: absolute;
  transform: translate(85%, 60%);
}

.curve:after {
  content: "";
  width: 55%;
  height: 100%;
  z-index: -1;
  background-color: #3c31dd;
  border-radius: 100% 50%;
  display: block;
  position: absolute;
  transform: translate(-4%, 40%);
}

.custom-shape-divider-top-1681741310 {
  width: 100%;
  line-height: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.custom-shape-divider-top-1681741310 svg {
  width: calc(127% + 1.3px);
  height: 100%;
  display: block;
  position: relative;
}

.custom-shape-divider-top-1681741310 .shape-fill {
  fill: #f8e71c;
}

.svg-container {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.b-form {
  background-repeat: no-repeat;
}

.shape-top, .form-shape-top {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.shape-bottom, .form-shape-bottom {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.b-faq {
  background-image: url("half-circle-svg-path.5ea9d889.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 180% 80%;
}

@media (max-width: 767px) {
  .b-faq {
    background-size: 100% 80%;
  }
}

.flickity-resize .carousel-cell {
  min-height: 100%;
}

@media (min-width: 1024px) {
  .lg\:container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 600px) {
    .lg\:container {
      max-width: 600px;
    }
  }

  @media (min-width: 728px) {
    .lg\:container {
      max-width: 728px;
    }
  }

  @media (min-width: 984px) {
    .lg\:container {
      max-width: 984px;
    }
  }
}

.hover\:bg-\[\#eefd00\]\/90:hover {
  background-color: #eefd00e6;
}

.hover\:bg-\[rgba\(0\,0\,0\,\.1\)\]:hover {
  background-color: #0000001a;
}

.hover\:bg-red-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-\[\#3b5998\]\/50:focus {
  --tw-ring-color: #3b599880;
}

.focus\:ring-red-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity));
}

@media (prefers-reduced-motion: no-preference) {
  .motion-safe\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
  }
}

@media (max-width: 500px) {
  .max-\[500px\]\:text-\[56px\] {
    font-size: 56px;
  }
}

@media (max-width: 450px) {
  .max-\[450px\]\:text-\[1\.7rem\] {
    font-size: 1.7rem;
  }
}

@media (max-width: 400px) {
  .max-\[400px\]\:text-\[43px\] {
    font-size: 43px;
  }

  .max-\[400px\]\:text-\[8px\] {
    font-size: 8px;
  }
}

@media (max-width: 380px) {
  .max-\[380px\]\:text-\[1\.4rem\] {
    font-size: 1.4rem;
  }
}

@media (max-width: 340px) {
  .max-\[340px\]\:text-\[33px\] {
    font-size: 33px;
  }
}

@media (min-width: 640px) {
  .sm\:left-\[20px\] {
    left: 20px;
  }

  .sm\:top-\[10\%\] {
    top: 10%;
  }

  .sm\:top-\[14px\] {
    top: 14px;
  }

  .sm\:h-\[90px\] {
    height: 90px;
  }

  .sm\:max-h-\[90px\] {
    max-height: 90px;
  }

  .sm\:max-w-\[130px\] {
    max-width: 130px;
  }

  .sm\:gap-8 {
    gap: 2rem;
  }

  .sm\:text-\[8rem\] {
    font-size: 8rem;
  }
}

@media (min-width: 768px) {
  .md\:left-\[50px\] {
    left: 50px;
  }

  .md\:top-\[82px\] {
    top: 82px;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:max-w-\[160px\] {
    max-width: 160px;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:p-3 {
    padding: .75rem;
  }

  .md\:pb-0 {
    padding-bottom: 0;
  }

  .md\:text-\[10rem\] {
    font-size: 10rem;
  }
}

@media (min-width: 1024px) {
  .lg\:left-\[100px\] {
    left: 100px;
  }

  .lg\:top-\[94px\] {
    top: 94px;
  }

  .lg\:h-\[120px\] {
    height: 120px;
  }

  .lg\:max-h-\[120px\] {
    max-height: 120px;
  }

  .lg\:text-\[12rem\] {
    font-size: 12rem;
  }
}

/*# sourceMappingURL=index.bd24939f.css.map */
