@tailwind base;
@tailwind components;
@tailwind utilities;

/* "Futura PT Cond Extra Bold" font */
@font-face {
	font-family: 'Futura PT Cond Extra Bold';
	src: url('../fonts/futura_pt/futura_pt_cond_extra_bold.eot'); /* IE 9 Compatibility Mode */
	src: url('../fonts/futura_pt/futura_pt_cond_extra_bold.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
		url('../fonts/futura_pt/futura_pt_cond_extra_bold.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/futura_pt/futura_pt_cond_extra_bold.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
		url('../fonts/futura_pt/futura_pt_cond_extra_bold.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/futura_pt/futura_pt_cond_extra_bold.svg#futura_pt_cond_extra_bold') format('svg'); /* Chrome < 4, Legacy iOS */
}

/* "Gotham" font */
@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-LightItalic.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Book.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-BookItalic.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-MediumItalic.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Medium.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-BoldItalic.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Light.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/gotham/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/gotham/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/* "Zing Rust D2 Demo Base" font */
@font-face {
    font-family: 'Zing Rust D2 Demo Base';
    src: url('../fonts/zing_rust_diagonals/ZingRustD2Demo-Base.woff2') format('woff2'),
        url('../fonts/zing_rust_diagonals/ZingRustD2Demo-Base.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}



[x-cloak] {
    display: none !important;
}

.loader {
    border-top-color: #3498db;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.font-reverse-slope {
    -moz-transform: scale(1) rotate(0deg) translate(0px, 0px) skew(15deg, 0deg);
    -webkit-transform: scale(1) rotate(0deg) translate(0px, 0px) skew(15deg, 0deg);
    -o-transform: scale(1) rotate(0deg) translate(0px, 0px) skew(15deg, 0deg);
    -ms-transform: scale(1) rotate(0deg) translate(0px, 0px) skew(15deg, 0deg);
    transform: scale(1) rotate(0deg) translate(0px, 0px) skew(15deg, 0deg);
}

.font-more-slope {
    transform: scale(1) rotate(0deg) translate(0px, 0px) skew(-14deg, 0deg);
}

html {
    scroll-behavior: smooth;
}

h2, h3 {
    font-family: 'Futura PT Cond Extra Bold';
    font-size: 3.5rem;
    line-height: 0.95;
    -moz-transform: scale(1) rotate(0deg) translate(0px, 0px) skew(15deg, 0deg);
    -webkit-transform: scale(1) rotate(0deg) translate(0px, 0px) skew(15deg, 0deg);
    -o-transform: scale(1) rotate(0deg) translate(0px, 0px) skew(15deg, 0deg);
    -ms-transform: scale(1) rotate(0deg) translate(0px, 0px) skew(15deg, 0deg);
    transform: scale(1) rotate(0deg) translate(0px, 0px) skew(15deg, 0deg);
}

h2 {
    font-size: 3.5rem;
    margin-left: -10px;
    margin-bottom: 30px;
}

h3 {
    font-size: 2.2rem;
    margin-bottom: 20px;
}

p {
    margin: 10px 0;
}

.curve {
    position: absolute;
    height: 250px;
    width: 100%;
    bottom: 0;
    text-align: center;
}

.curve::before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 55%;
    height: 100%;
    transform: translate(85%, 60%);
    background-color: hsl(216, 21%, 16%);
}

.curve::after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 55%;
    height: 100%;
    background-color: #3c31dd;
    transform: translate(-4%, 40%);
    z-index: -1;
}

.custom-shape-divider-top-1681741310 {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1681741310 svg {
    position: relative;
    display: block;
    width: calc(127% + 1.3px);
    height: 100%;
}

.custom-shape-divider-top-1681741310 .shape-fill {
    fill: #F8E71C;
}

.svg-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
}

.b-form {
    background-repeat: no-repeat;
}

.shape-top,
.form-shape-top {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
}

.shape-bottom,
.form-shape-bottom {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
}

.b-faq {
    background-image: url('../img/half-circle-svg-path.svg');
    background-position: bottom;
    background-size: 180% 80%;
    background-repeat: no-repeat;
}

/* mobile landscape */
@media (max-width: 767px) {
    .b-faq {
        background-size: 100% 80%;
    }
}

.flickity-resize .carousel-cell {
    min-height: 100%;
}
